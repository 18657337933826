import React from 'react';
import { Heading, Box, Text, OrderedList, ListItem } from '@chakra-ui/react';

const AboutPage = () => {
    return (
        <Box>
            <Heading mb={4}>About LoRA Garden</Heading>
            <Text mb={4}>
                LoRA Garden is a web application designed to streamline prompt engineering for image generation. It allows users to search for LoRAs (image generation modification models), select reference images generated by the community, pull the prompts used to generate those images, and merge them together guided by the user's desired output.
            </Text>

            <Heading size="md" mb={2}>How to Use LoRA Garden</Heading>
            <OrderedList spacing={3} mt={4}>
                <ListItem>
                    Go to the <strong>My Garden</strong> page.
                </ListItem>
                <ListItem>
                    Create a new <strong>Container</strong> by entering a name and clicking <strong>Add Container</strong>.
                </ListItem>
                <ListItem>
                    Navigate to the <strong>Search</strong> page to find LoRAs you're interested in.
                </ListItem>
                <ListItem>
                    Add LoRAs to your container(s) by clicking the <strong>Add to Container</strong> button on each LoRA.
                </ListItem>
                <ListItem>
                    Return to <strong>My Garden</strong> and select your container to view its contents.
                </ListItem>
                <ListItem>
                    Inside the container, select reference images by clicking on them (a checkmark will appear on selected images).
                </ListItem>
                <ListItem>
                    Enter your desired prompt in the prompt input field at the bottom of the container page.
                </ListItem>
                <ListItem>
                    Click the <strong>Generate</strong> button to create the final output prompt.
                </ListItem>
                <ListItem>
                    Use the generated prompt for your image generation tasks.
                </ListItem>
            </OrderedList>
        </Box>
    );
};

export default AboutPage;