import React from 'react';
import { BrowserRouter as Router, Routes, Route, Link as RouterLink } from 'react-router-dom';
import { ChakraProvider, Container, HStack, Button, Image, Flex, Box, Link as ChakraLink, Text } from '@chakra-ui/react';
import { FaGithub } from 'react-icons/fa'; // Import GitHub icon

import ContainerDetailPage from './pages/ContainerDetailPage';
import SearchPage from './pages/SearchPage';
import GardenPage from './pages/GardenPage';
import AboutPage from './pages/AboutPage';

import ReactGA from "react-ga4";
ReactGA.initialize("G-CN8J272HEK");

function App() {
  return (
      <ChakraProvider>
        <Router>
          <Container maxW="container.xl" py={5}>
            <Flex align="end" mb={6} gap={4}>
              <RouterLink to="/">
                <Image src="/lora_garden_logo.png" alt="LoRA Garden Logo" height="250px" objectFit="contain" mr={4} />
              </RouterLink>
              <HStack spacing={4}>
                <RouterLink to="/">
                  <Button>Search</Button>
                </RouterLink>
                <RouterLink to="/garden">
                  <Button>My Garden</Button>
                </RouterLink>
                <RouterLink to="/about">
                  <Button>About</Button>
                </RouterLink>
              </HStack>
            </Flex>

            <Routes>
              <Route path="/" element={<SearchPage />} />
              <Route path="/garden" element={<GardenPage />} />
              <Route path="/garden/:containerName" element={<ContainerDetailPage />} />
              <Route path="/about" element={<AboutPage />} />
            </Routes>

            {/* Updated Footer */}
            <Box as="footer" py={4} textAlign="center">
              <Text>
                Contact:{' '}
                <ChakraLink href="mailto:general@lora.garden">general@lora.garden</ChakraLink>
                {' | '}
                Open Source:{' '}
                <ChakraLink href="https://github.com/SnyderConsulting/LoRA-Garden" isExternal>
                  <HStack as="span" spacing={1} display="inline-flex">
                    <FaGithub />
                    <Text as="span">GitHub</Text>
                  </HStack>
                </ChakraLink>
              </Text>
            </Box>
          </Container>
        </Router>
      </ChakraProvider>
  );
}

export default App;