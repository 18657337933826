import axios from 'axios';
import { getUserId } from './utils/userId';

axios.defaults.baseURL = '/api';

axios.interceptors.request.use(
    (config) => {
        const userId = getUserId();
        if (userId) {
            config.headers['x-user-id'] = userId;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

export default axios;