import React, { useState, useEffect, useCallback } from 'react';
import {
    Grid,
    Input,
    IconButton,
    HStack,
    useToast,
    Text,
    Spinner
} from '@chakra-ui/react';
import { SearchIcon } from '@chakra-ui/icons';
import axios from '../api';
import ModelCard from '../components/ModelCard';
import ReactGA from "react-ga4";

function SearchPage() {
    const [models, setModels] = useState([]);
    const [containers, setContainers] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [loading, setLoading] = useState(false);
    const toast = useToast();

    const fetchModels = useCallback(
        async (query) => {
            try {
                setLoading(true); // Start loading
                const response = await axios.get('/models', {
                    params: {
                        query: query || undefined,
                        limit: 20,
                    },
                });
                console.log('Received response data:', response.data);

                ReactGA.event({
                    category: "API",
                    action: "Performed search",
                    label: "Search"
                });

                // Ensure models is an array
                if (Array.isArray(response.data.models)) {
                    setModels(response.data.models);
                } else {
                    setModels([]); // Fallback to empty array
                }
            } catch (error) {
                console.error('Error fetching models:', error);
                toast({
                    title: 'Error fetching models',
                    status: 'error',
                    duration: 3000,
                    isClosable: true,
                });
            } finally {
                setLoading(false); // Stop loading
            }
        },
        [toast]
    );

    const fetchContainers = useCallback(async () => {
        try {
            const response = await axios.get('/garden');
            setContainers(response.data.containers || []);
        } catch (error) {
            console.error('Error fetching containers:', error);
            toast({
                title: 'Error fetching containers',
                status: 'error',
                duration: 3000,
                isClosable: true,
            });
        }
    }, [toast]);

    useEffect(() => {
        fetchModels();
        fetchContainers();
    }, [fetchModels, fetchContainers]);

    const handleSearch = (e) => {
        if (e.key === 'Enter') {
            fetchModels(searchQuery);
        }
    };

    const handleAddToContainer = async (modelId, containerName) => {
        try {
            await axios.post('/garden/containers/add-lora', {
                container_name: containerName,
                lora_id: modelId,
            });
            ReactGA.event({
                category: "API",
                action: "Added LoRA to container",
                label: "Containers"
            });
            toast({
                title: `Added to ${containerName}!`,
                status: 'success',
                duration: 2000,
                isClosable: true,
            });
            // Refresh containers after adding
            fetchContainers();
        } catch (error) {
            console.error('Error adding LoRA:', error);
            toast({
                title: 'Error adding to container',
                status: 'error',
                duration: 2000,
                isClosable: true,
            });
        }
    };

    return (
        <>
            <HStack mb={6}>
                <Input
                    type="text"
                    placeholder="Search for models..."
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    onKeyDown={handleSearch}
                />
                <IconButton
                    icon={<SearchIcon />}
                    onClick={() => fetchModels(searchQuery)}
                    aria-label="Search"
                />
            </HStack>
            {loading ? (
                <Spinner size="xl" />
            ) : models && models.length > 0 ? (
                <Grid templateColumns="repeat(auto-fill, minmax(250px, 1fr))" gap={6}>
                    {models.map((model) => (
                        <ModelCard
                            key={model.id}
                            model={model}
                            containers={containers}
                            onAddToContainer={handleAddToContainer}
                        />
                    ))}
                </Grid>
            ) : (
                <Text>No models found.</Text>
            )}
        </>
    );
}

export default SearchPage;